import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { parse } from 'query-string'
import { categories, years } from '../constants'

const Sidebar = ({ isExtended, onSubmit, history, location }) => {
    const qs = parse(location.search)

    const [ query, setQuery ] = useState(qs.query || '')
    const [ category, setCategory ] = useState('')
    const [ year, setYear ] = useState('')

    const [ open, setOpen ] = useState(false)

    const submitHandler = e => {
        if (e) {
            e.preventDefault()
        }

        onSubmit ? onSubmit(query, year, category) : history.push(`/search?query=${query}`)
    }

    const resetSearch = () => {
        setQuery('')
        setCategory('')
        setYear('')

        onSubmit()
    }

    return (
        <aside className="site-sidebar">
            <button className="button button-primary sidebar-toggle" onClick={() => setOpen(!open)}>
                Suchkriterien {open ? 'ausblenden' : 'anzeigen'}
            </button>
            <div className={`search-wrapper ${open ? 'is-open' : ''}`}>
                <form onSubmit={submitHandler}>
                    <label htmlFor="search">Stichwortsuche</label>
                    <div className="input-group">
                        <input id="search" type="search" placeholder="Suchbegriff eingeben…" value={query} onChange={e => setQuery(e.target.value)} />
                    </div>
                    {isExtended && (
                        <>
                            <label htmlFor="yeaer">Jahr</label>
                            <div className="input-group">
                                <select id="year" value={year} onChange={e => setYear(e.target.value)}>
                                    <option></option>
                                    {years.map(y => <option key={y}>{y}</option>)}
                                </select>
                            </div>
                            <label htmlFor="category">Kategorie</label>
                            <div className="input-group">
                                <select id="category" value={category} onChange={e => setCategory(e.target.value)}>
                                    <option></option>
                                    {categories.map(c => <option key={c}>{c}</option>)}
                                </select>
                            </div>
                        </>
                    )}
                </form>
                <button className="button button-primary" onClick={submitHandler}>Suchen</button>
                {!isExtended && <Link to="/search" className="button">Erweiterte Suche</Link>}
                {isExtended && <button className="button" onClick={resetSearch}>Suche zurücksetzen</button>}
            </div>
        </aside>
    )
}

export default withRouter(Sidebar)
