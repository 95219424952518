import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Sidebar from '../components/Sidebar'

const formatDate = timestamp => {
    const date = new Date(timestamp)
    return timestamp ? `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}` : ''
}

const ArticlePage = ({ match }) => {
    const [ article, setArticle ] = useState({})

    const fetchArticle = async () => {
        const { data: { article } } = await axios.get(`/api/articles/${match.params.id}`)
        setArticle(article)
    }

    useEffect(() => {
        fetchArticle()
    }, [])

    return (
        <>
            <Sidebar isExtended={false} />
            <section className="page-content">
                <div className="article-meta">
                    {formatDate(article.date)} | {article.category} {article.subcategory ? `| ${article.subcategory}` : ''}
                </div>
                <h1>{article.title}</h1>
                <div className="article-content" dangerouslySetInnerHTML={{ __html: article.content }} />
            </section>
        </>
    )
}

export default withRouter(ArticlePage)
