export const categories = [
    'Amtlicher Wohnungsanzeiger',
    'Auswärtige Submissionen gemäss Gegenrechtsvereinbarung',
    'Basler Verkehrs-Betriebe (BVB)',
    'BSABB, BVG- und Stiftungsaufsicht beider Basel',
    'Bundesbehörden',
    'Departemente',
    'Gebäudeversicherung des Kantons Basel-Stadt',
    'Gemeinden',
    'Gerichte',
    'Grosser Rat',
    'Handelsregister',
    'Industrielle Werke',
    'Jugendanwaltschaft',
    'Kirchen',
    'Pensionskasse Basel-Stadt',
    'Regierungsrat',
    'Schweizerische Rheinhäfen',
    'Staatsanwalt',
    'Staatskanzlei',
    'Stellenausschreibungen der Öffentlichen Verwaltung Basel-Stadt',
    'Submissionen und Zuschläge',
    'Texte für die Gesetzessammlung',
    'Universität',
    'Universität Basel',
    'Verfassungsrat',
    'Verschiedenes',
    'Zivilstand'
]

export const years = [
    '2018',
    '2017',
    '2016',
    '2015',
    '2014',
    '2013',
    '2012',
    '2011',
    '2010',
    '2009',
    '2008',
    '2007',
    '2006',
    '2005',
    '2004',
    '2003',
    '2002',
    '2001',
    '2000'
]

export const monthNames = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
]

export const dayNames = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag'
]
