import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Sidebar from '../components/Sidebar'
import { years, monthNames } from '../constants'
import { entries } from '../helpers'

const renderMonthView = (month, issues) => (
    <div className="month-view" key={month}>
        <div className="month-title">{monthNames[month]}</div>
        <ul className="issue-list">
            {issues.map(issue => (
                <li key={issue.id}>
                    <Link to={`/issues/${issue.id}`}>{issue.number.replace(/^0+/, '')}</Link>
                </li>
            ))}
        </ul>
    </div>
)

const IssueListPage = () => {
    const [ year, setYear ] = useState(2018)
    const [ groupedIssues, setGroupedIssues ] = useState([])

    const fetchIssues = async year => {
        setYear(year)

        const { data: { issues } } = await axios.get(`/api/issues?year=${year}`)
        const grouped = {}

        issues.forEach(issue => {
            const date = new Date(issue.date)

            grouped[date.getMonth()] = grouped[date.getMonth()] || []
            grouped[date.getMonth()].push(issue)
        })

        setGroupedIssues(grouped)
    }

    useEffect(() => {
        fetchIssues(2018)
    }, [])

    return (
        <>
            <Sidebar isExtended={false} />
            <section className="page-content">
                <div className="widget">
                    <label for="year">Jahr</label>
                    <select id="year" value={year} onChange={e => fetchIssues(e.target.value)}>
                        {years.map(y => <option key={y}>{y}</option>)}
                    </select>
                </div>
                <div className="year-view">
                    <div className="semester-view">
                        <div className="semester-title">1. Semester</div>
                        {entries(groupedIssues).slice(0, 6).map(([ month, issues ]) => renderMonthView(month, issues))}
                    </div>
                    <div className="semester-view">
                        <div className="semester-title">2. Semester</div>
                        {entries(groupedIssues).slice(6).map(([ month, issues ]) => renderMonthView(month, issues))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default IssueListPage
