import "babel-polyfill"
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Page from './pages/Page'
import IssueListPage from './pages/IssueListPage'
import IssuePage from './pages/IssuePage'
import ArticlePage from './pages/ArticlePage'
import SearchPage from './pages/SearchPage'
import './styles/index.scss'

render(
    <BrowserRouter>
        <Page>
            <Switch>
                <Route path="/articles/:id" component={ArticlePage} />
                <Route path="/issues/:id" component={IssuePage} />
                <Route path="/issues" component={IssueListPage} />
                <Route path="/search" component={SearchPage} />
                <Redirect to="/issues" />
            </Switch>
        </Page>
    </BrowserRouter>,
    document.getElementById('root')
)
