import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Page = ({ children, location }) => (
    <div className="site">
        <Header />
        <main className="site-main">
            {children}
        </main>
        <Footer />
    </div>
)

export default Page
