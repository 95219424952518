import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import { parse, stringify } from 'query-string'
import Sidebar from '../components/Sidebar'

const formatDate = timestamp => {
    const date = new Date(timestamp)
    return timestamp ? `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}.${date.getFullYear()}` : ''
}

const SearchPage = ({ location }) => {
    const [ articles, setArticles ] = useState([])

    const searchArticles = async (query, year, category) => {
        const qs = stringify({
            query: query || undefined,
            year: year || undefined,
            category: category || undefined
        })

        const { data: { articles } } = await axios.get(`/api/search?${qs}`)
        setArticles(articles)
    }

    useEffect(() => {
        const qs = parse(location.search)
        searchArticles(qs.query)
    }, [])

    return (
        <>
            <Sidebar isExtended={true} onSubmit={searchArticles} />
            <section className="page-content">
                <h2>{articles.length} Treffer</h2>
                <ul className="article-list">
                    {articles.map(article => (
                        <li key={article.id}>
                            <Link to={`/articles/${article.id}`} className="article-link">{article.title}</Link>
                            <div>{formatDate(article.date)}</div>
                        </li>
                    ))}
                </ul>
            </section>
        </>
    )
}

export default withRouter(SearchPage)
