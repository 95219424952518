import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../images/logo.png'
import logoAlt from '../images/logo-alt.png'
import menu from '../images/menu.svg'

const Header = () => {
    const [ expanded, setExpanded ] = useState(false)

    return (
        <header className="site-header">
            <div className="site-logo">
                <img className="logo" src={logo} alt="Kantonsblatt Logo" />
                <img className="logo-alt" src={logoAlt} alt="Kantonsblatt Logo" />
            </div>
            <nav className={`site-navigation ${expanded ? 'is-expanded' : ''}`}>
                <ul>
                    <li onClick={() => setExpanded(!expanded)}>
                        <button>
                            <img src={menu} alt="Menu" />
                            Menü {expanded ? 'ausblenden' : 'anzeigen'}
                        </button>
                    </li>
                    <li>
                        <NavLink to="/issues" activeClassName="active">Startseite</NavLink>
                    </li>
                    <li>
                        <a href="https://kantonsblatt.ch">Kantonsblatt Basel-Stadt</a>
                    </li>
                    <li>
                        <NavLink to="/search" activeClassName="active">Suche</NavLink>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Header
