import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import Sidebar from '../components/Sidebar'
import { dayNames, monthNames } from '../constants'
import { entries } from '../helpers'
import pdfIcon from '../images/pdf.gif'

const formatDate = timestamp => {
    const date = new Date(timestamp)
    return timestamp ? `${dayNames[date.getDay()]}, ${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}. ${monthNames[date.getMonth()]} ${date.getFullYear()}` : ''
}

const getIssuePdfUrl = (number, timestamp) => {
    if (!number || !timestamp) return ''
    const year = (new Date(timestamp)).getFullYear() + ''
    return `/_Resources/Static/Packages/MM.KBA/pdf/${year}/kb${year.substr(2)}_${number}.pdf`
}

const IssuePage = ({ match }) => {
    const [ issue, setIssue ] = useState({ articles: {} })

    const fetchIssue = async () => {
        const { data: { issue } } = await axios.get(`/api/issues/${match.params.id}`)
        const grouped = {}

        issue.articles.forEach(article => {
            grouped[article.category] = grouped[article.category] || {}
            grouped[article.category][article.subcategory || 'general'] = grouped[article.category][article.subcategory || 'general'] || []
            grouped[article.category][article.subcategory || 'general'].push(article)
        })

        issue.articles = grouped

        setIssue(issue)
    }

    useEffect(() => {
        fetchIssue()
    }, [])

    return (
        <>
            <Sidebar isExtended={false} />
            <section className="page-content">
                <div className="article-meta">
                    {formatDate(issue.date)} | <a href={getIssuePdfUrl(issue.number, issue.date)} download><span>Kantonsblatt im PDF Format</span><img src={pdfIcon} alt="PDF icon" /></a>
                </div>
                <h1>Kantonsblatt Nr. {issue.number}</h1>
                {entries(issue.articles).map(([ category, subs ]) => (
                    <React.Fragment key={category}>
                        <h2 className="issue-category">{category}</h2>
                        {entries(subs).map(([ sub, articles ]) => (
                            <React.Fragment key={sub}>
                                {sub !== 'general' ? <h3 className="issue-subcategory">{sub}</h3> : ''}
                                <ul className="article-list">
                                {articles.map(article => (
                                    <li key={article.id}>
                                    <Link to={`/articles/${article.id}`} className="article-link">{article.title}</Link>
                                    </li>
                                ))}
                                </ul>
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </section>
        </>
    )
}

export default withRouter(IssuePage)
